import useEnv from "./useEnv";
import { useQuery } from "react-query";

const useAPI = (key, path) => {
  const { apiUrl } = useEnv();

  const fetchAPI = async () => {
    const res = await fetch(`${apiUrl}${path}`, {
      credentials: "include",
      // mode: 'no-cors'
    });

    return res.json();
  };

  return useQuery(key, fetchAPI);
};

export default useAPI;
