import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import "./index.css";

if (process.env.REACT_APP_THEME === 'gea') {
  import('./styles/themeGea.css');
} else if (process.env.REACT_APP_THEME === 'gecontur') {
  import('./styles/themeGecontur.css');
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
