import Loading from "components/Loading";
import useOffers from "hooks/useOffers";
import useOffersFilter from "hooks/useOffersFilter";
import MenuOfferItem from "./MenuOfferItem";
import MenuOffersFilter from "./MenuOffersFilter";

const MenuOffers = () => {
  const { isLoading, data } = useOffers();
  const { offers, categories, programs, tags, updateFilter } = useOffersFilter(
    data?.offers || []
  );

  const onFilterChange = (categories, tags, programs) => {
    updateFilter(categories, tags, programs);
  };

  return (
    <>
      <div className="m-3">
        {!isLoading && (
          <MenuOffersFilter
            categoriesOptions={categories}
            programsOptions={programs}
            tagsOptions={tags}
            onFilterChange={onFilterChange}
          />
        )}
      </div>
      <div
        className="flow-root min-h-6 overflow-auto"
        style={{ minHeight: "500px", height: "calc(100vh - 120px)" }}
      >
        {isLoading && (
          <div className="flex mt-40 justify-center">
            <Loading />
          </div>
        )}
        {!isLoading && (
          <ul className="-my-3 divide-y divide-gray-200">
            {offers.map((offer) => (
              <MenuOfferItem
                key={`${offer.Type}-${offer.CustomOfferID}`}
                offer={offer}
              />
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default MenuOffers;
