import useEnv from "hooks/useEnv";

const IconWithText = ({ src, alt, style, children }) => (
  <table
    cellpadding="0"
    cellspacing="0"
    border="0"
    style={{ display: "inline-block", ...style }}
  >
    <tr>
      <td valign="middle" style={{ paddingRight: "5px" }}>
        <img
          src={src}
          alt={alt}
          style={{ verticalAlign: "middle", width: "14px" }}
        />
      </td>
      <td
        valign="middle"
        style={{
          lineHeight: "1.2",
          fontFamily: "NoirPro-Regular, helvetica",
          color: "#555555",
          fontSize: 10,
        }}
      >
        <span contentEditable>{children}</span>
      </td>
    </tr>
  </table>
);

const InLineElements = ({ firstElement, secondElement }) => (
  <table cellpadding="0" cellspacing="0" border="0" width="100%">
    <tr>
      <td align="left" valign="top">
        <div style={{ display: "inline-block", width: "auto" }}>
          {firstElement}
        </div>
      </td>
      <td align="left" valign="top">
        <div style={{ display: "inline-block", width: "auto" }}>
          {secondElement}
        </div>
      </td>
    </tr>
  </table>
);

const WithPhoto = ({ user, withPhoto }) => {
  const { geconturUrl } = useEnv();
  const icons = {
    phone: `${geconturUrl}/assinatura/icon-telefone.png`,
    email: `${geconturUrl}/assinatura/icon-email.png`,
    facebook: `${geconturUrl}/assinatura/icon-facebook.png`,
    instagram: `${geconturUrl}/assinatura/icon-instagram.png`,
    linkedin: `${geconturUrl}/assinatura/icon-linkedin.png`,
    morada: `${geconturUrl}/assinatura/icon-morada.png`,
    site: `${geconturUrl}/assinatura/icon-site.png`,
    logo: `${geconturUrl}/assinatura/logo.png`,
    documentos: `${geconturUrl}/assinatura/icon-documentos-legais.png`,
  };
  const {
    name,
    agency,
    role,
    phoneNumber1,
    phoneNumber2,
    email,
    website,
    address,
    photo,
    facebook,
    instagram,
    linkedin,
    documents,
  } = user;

  return (
    <table
      border={0}
      cellPadding={0}
      cellSpacing={0}
      width={600}
      style={{
        width: 600,
        height: 200,
        backgroundRepeat: "no-repeat",
        backgroundSize: "50px 100%",
        wordBreak: "break-word",
        background: "#F6F6F6",
      }}
    >
      <tbody>
        <tr height={20}>
          <td height={20} colspan="5" />
        </tr>
        <tr>
          <td width={150} height={183} align="center">
            <div style={{ margin: "0 auto", width: "110px" }}>
              <img
                src={icons.logo}
                alt="Bestravel"
                style={{ width: 110, display: "block" }}
              />
            </div>
          </td>
          <td
            width={1}
            height={183}
            style={{ borderLeft: "1px solid #00205c" }}
          ></td>
          <td width={15} height={183}>
            &nbsp;
          </td>
          <td
            width={279}
            height={183}
            valign="top"
            style={{ textAlign: "left" }}
          >
            <h3
              style={{
                margin: "0 0 2px",
                fontWeight: "bold",
                fontSize: 16,
                color: "#00205c",
                fontFamily: "NoirPro-Bold, helvetica",
                textTransform: "uppercase",
              }}
              contentEditable
            >
              {name}
            </h3>
            <h5
              style={{
                margin: "0 0 4px",
                fontWeight: "bold",
                fontSize: 14,
                color: "#00205c",
                fontFamily: "NoirPro-Bold, helvetica",
              }}
              contentEditable
            >
              {role}
            </h5>
            <p
              style={{
                margin: "0 0 5px",
                fontSize: 9,
                color: "#00205c",
                fontFamily: "NoirPro-Regular, helvetica",
                height: "14px",
              }}
              contentEditable
            >
              {agency}
            </p>

            <InLineElements
              firstElement={
                <IconWithText src={icons.phone} alt="Telefone">
                  {phoneNumber1}*
                </IconWithText>
              }
              secondElement={
                <IconWithText
                  src={icons.phone}
                  alt="Telefone"
                  style={{ marginLeft: "15px" }}
                >
                  {phoneNumber2}**
                </IconWithText>
              }
            />

            <InLineElements
              firstElement={
                <IconWithText src={icons.email} alt="Email">
                  <a
                    style={{ textDecoration: "none", color: "#555555" }}
                    href={`mailto:${email}`}
                  >
                    {email}
                  </a>
                </IconWithText>
              }
              secondElement={
                <IconWithText
                  src={icons.site}
                  alt="Website"
                  style={{ marginLeft: "15px" }}
                >
                  <a
                    href={`https://${website}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#555555", textDecoration: "none" }}
                  >
                    {website}
                  </a>
                </IconWithText>
              }
            />

            <IconWithText src={icons.morada} alt="Morada">
              <div
                style={{
                  whiteSpace: "break-spaces",
                  float: "left",
                  lineHeight: "1.2",
                  fontFamily: "NoirPro-Regular, helvetica",
                  color: "#555555",
                  fontSize: 10,
                }}
              >
                {address}
              </div>
            </IconWithText>

            <div
              style={{
                textAlign: "left",
                height: 25,
                margin: "10px 0px 0px 0px",
              }}
            >
              <a
                href={facebook}
                style={{ textDecoration: "none" }}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="Rede Social Facebook"
                  src={icons.facebook}
                  style={{ width: 20, display: "inline-block" }}
                />
              </a>
              <a
                href={instagram}
                style={{ textDecoration: "none", marginLeft: "5px" }}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="Rede Social Instagram"
                  src={icons.instagram}
                  style={{ width: 20, display: "inline-block" }}
                />
              </a>
              <a
                href={linkedin}
                style={{ textDecoration: "none", marginLeft: "5px" }}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="Rede Social Linkedin"
                  src={icons.linkedin}
                  style={{ width: 20, display: "inline-block" }}
                />
              </a>
              <a
                href={documents}
                style={{ textDecoration: "none", marginLeft: "5px" }}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="Documentos Legais"
                  src={icons.documentos}
                  style={{ width: 20, display: "inline-block" }}
                />
              </a>
            </div>
          </td>
          <td width={155} height={183} valign="middle">
            {withPhoto && (
              <div style={{ margin: "0 auto", width: "119px" }}>
                <img
                  alt="Colaborador Bestravel"
                  style={{
                    width: 115,
                    height: 115,
                    display: "block",
                    borderRadius: "50%",
                    border: "solid 2px #00205c",
                    objectFit: "cover",
                  }}
                  src={photo}
                />
              </div>
            )}
          </td>
        </tr>
        <tr>
          <td width={151} height={20} colSpan={2} />
          <td
            height={20}
            colSpan={3}
            style={{ padding: "8px 0px 0px 18px", textAlign: "left" }}
          >
            <p
              style={{
                fontSize: 8,
                fontFamily: "NoirPro-Light, helvetica",
                color: "#555555",
                margin: 0,
              }}
            >
              * Custo de chamada para a rede móvel &nbsp; **Custo de chamada
              local para a rede fixa
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default WithPhoto;
