import { useState } from "react";
import { unique, groupBy } from "helpers/array";

const transform = (options) =>
  options.map((o) => ({
    label: o,
    value: encodeURI(o),
  }));

const INITIAL_STATE = {
  category: "",
  tag: "",
  program: "",
  priceMin: "",
  priceMax: "",
};
const useOffersFilter = (oo) => {
  const [filter, setFilter] = useState(INITIAL_STATE);

  const { category, tag, program, priceMin, priceMax } = filter;

  const offersCat = oo.filter(
    ({ Category }) => !category || encodeURI(Category) === category
  );
  const offersOpt = offersCat.filter(
    ({ OfferTitle }) => !program || encodeURI(OfferTitle) === program
  );
  const offersRes1 = offersOpt.filter(
    ({ tags }) => !tag || (tags || []).map(encodeURI).includes(tag)
  );
  const offersRes2 = offersRes1.filter(
    ({ LeadInPrice }) => !priceMin || LeadInPrice > parseInt(priceMin)
  );
  const offersRes3 = offersRes2.filter(
    ({ LeadInPrice }) => !priceMax || LeadInPrice < parseInt(priceMax)
  );

  return {
    offers: offersRes3,
    updateFilter: setFilter,
    categories: transform(
      groupBy(oo, "Category").filter(unique).sort(Intl.Collator().compare)
    ),
    programs: transform(
      groupBy(offersCat, "OfferTitle")
        .filter(unique)
        .sort(Intl.Collator().compare)
    ),
    tags: transform(
      groupBy(offersCat, "tags")
        .flat()
        .filter(unique)
        .sort(Intl.Collator().compare)
    ),
  };
};

export default useOffersFilter;
