import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Input from "components/Input";
import InputSelect from "components/InputSelect";

const MenuOffersFilter = ({
  categoriesOptions,
  programsOptions,
  tagsOptions,
  onFilterChange,
}) => {
  const { register, watch, setValue } = useForm({
    defaultValues: {
      tags: "",
      program: "",
      category: "",
      priceMin: "",
      priceMax: "",
    },
  });

  const tag = watch("tag");
  const program = watch("program");
  const category = watch("category");
  const priceMin = watch("priceMin");
  const priceMax = watch("priceMax");

  useEffect(() => {
    setValue("tag", "");
    setValue("program", "");
  }, [category, setValue]);

  useEffect(() => {
    onFilterChange({ category, tag, program, priceMin, priceMax });
    // eslint-disable-next-line
  }, [category, tag, program, priceMin, priceMax]);

  return (
    <form>
      <div className="grid grid-cols-12 gap-3">
        <div className="col-span-4 flex flex-col items-center">
          <InputSelect
            name="category"
            register={register}
            options={[{ label: "Categoria", value: "" }, ...categoriesOptions]}
          />
        </div>
        <div className="col-span-4 flex flex-col items-center">
          <InputSelect
            name="tag"
            register={register}
            options={[{ label: "Tag", value: "" }, ...tagsOptions]}
          />
        </div>
        <div className="col-span-4 flex flex-col items-center">
          <InputSelect
            name="program"
            register={register}
            options={[{ label: "Programa", value: "" }, ...programsOptions]}
          />
        </div>
        <div className="col-span-6 flex flex-col items-center">
          <Input name="priceMin" register={register} placeholder="Valor min" />
        </div>
        <div className="col-span-6 flex flex-col items-center">
          <Input name="priceMax" register={register} placeholder="Valor max" />
        </div>
      </div>
    </form>
  );
};

export default MenuOffersFilter;
