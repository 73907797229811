import MenuItem from "./MenuItem";
import useLeftMenu from "hooks/useLeftMenu";

import styles from "./Sidebar.module.css";

const Sidebar = () => {
  const menus = useLeftMenu();
  return (
    <div className={styles.Root}>
      {menus.map((menu) => {
        return (
          <MenuItem
            key={menu.label}
            route={menu.route}
            icon={menu.icon}
            label={menu.label}
            external={menu.external}
          />
        );
      })}
    </div>
  );
};

export default Sidebar;
