import Button from "components/Button";
import Loading from "components/Loading";
import useOffer from "hooks/useOffer";
import useToast from "hooks/useToast";
import useUser from "hooks/useUser";
import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { isFirefox } from "react-device-detect";

import ComponentToPrint from "./components/ComponentToPrint";
import ComponentToPrintGea from "./components/ComponentToPrintGea";
import useEnv from "hooks/useEnv";

const OffersPdf = () => {
  const { toast } = useToast();
  const componentRef = useRef();
  const { isGea, isGecontur } = useEnv()
  const { offerId } = useParams();
  const { isLoading, offer } = useOffer(offerId);
  const { data } = useUser();
  const print = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `post-${offerId}`,
  });

  const handlePrint = () => {
    if (isFirefox) {
      toast(
        "warning",
        'Atenção: Se a imagem não aparecer, habilite a opção "Imprimir fundos" em "Mais definições"'
      );
    }

    print();
  };

  if (isLoading) {
    return (
      <div className="flex mt-40 justify-center">
        <Loading />
      </div>
    );
  }

  return (
    <>
      {offer && isGea &&
        <ComponentToPrintGea
          ref={componentRef}
          offer={offer}
          agency={data?.agency}
        />}
      {offer && isGecontur &&
        <ComponentToPrint
          ref={componentRef}
          offer={offer}
          agency={data?.agency}
        />
      }
      <div className="mt-4 flex justify-center">
        <Button primary medium onClick={handlePrint}>
          Download Pdf
        </Button>
      </div>
    </>
  );
};

export default OffersPdf;
