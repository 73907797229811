import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useOffer from "hooks/useOffer";
import { toPng } from "html-to-image";

import MenuRadio from "components/MenuRadio";
import Button from "components/Button";
import useEnv from "hooks/useEnv";
import useUser from "hooks/useUser";

import styles from "./PostsGea.module.css";

const variants = {
  transparent: styles.Transparent,
  blue: styles.Blue,
  yellow: styles.Yellow,
  top: styles.Top,
  center: styles.Center,
  fb: styles.Facebook,
  insta: styles.Instagram,
  subOpaque: styles.SubOpaque,
  subFullTransparent: styles.SubFullTransparent,
  subTransparent: styles.SubTransparent,
};
const PostsGea = () => {
  const { offerId } = useParams();
  const { isLoading, offer } = useOffer(offerId);
  const domEl = useRef(null);
  const [size, setSize] = useState("fb");
  const [titleColor, setTitleColor] = useState("white");

  const { geconturUrl } = useEnv();
  const { data } = useUser();

  const sizeOptions = [
    { label: "Post", value: "fb" },
    { label: "Story", value: "insta" },
  ];
  const titleOptions = [
    { label: "Cinza", value: "#575756" },
    { label: "Branco", value: "white" },
  ];

  const downloadImage = async () => {
    const dataUrl = await toPng(domEl.current);

    // download image
    const link = document.createElement("a");
    link.download = `oferta-${offerId}.png`;
    link.href = dataUrl;
    link.click();
  };

  if (isLoading || !offer) {
    return null;
  }

  const {
    AccommodationName,
    OfferTitle,
    LeadInPrice,
    PriceType,
    ImageURL,
    Nights,
  } = offer;
  

  console.log("manageUsers", offer, data.can.manageUsers)

  const color = "#4fab67"

  const logo = `${geconturUrl}/img/agencies/${data.agency?.logo_path}`

  return (
    <main className={`px-4 ${variants[size]}`}>
      <div className="mx-auto grid justify-center px-4">
        <div className={styles.FrameWrapper}>
          <div className={styles.Frame}>

            <div ref={domEl} >
              <div className={`${styles.Root}`}>
                <img alt={OfferTitle} className={styles.Logo} src={logo} />
                <img className={styles.CoverImage} alt={OfferTitle} src={ImageURL} />

                <div className={styles.Content}>
                  <div className={styles.Title} style={{ background: color, color: titleColor }} contentEditable="true">
                    {OfferTitle}
                  </div>
                  <div className={styles.Body}>
                    <div className={styles.AccommodationName} contentEditable="true">
                      {AccommodationName}
                    </div>

                    <div className={styles.Nights} contentEditable="true">
                      {Nights && `${Nights} noites`}
                    </div>

                    <div className="flex justify-left gap-x-2 mt-6 mb-12 pt-2">
                      <div className="flex-60 w-1/8">
                        <div className="ComponentToPrintGea_Desde__c2MKi">{PriceType}</div>
                        <div className="ComponentToPrintGea_Moeda__c18Xu" style={{ color }}>€</div>
                      </div>
                      <div className="flex-40 w-7/8">
                        <div className="ComponentToPrintGea_Price__8AAgY" style={{ color }}>{LeadInPrice}</div>
                      </div>
                    </div>

                    <div className={styles.RNAVT}>
                      RNAVT {data.agency.RNAVT}
                    </div>

                  </div>

                </div>

              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="w-3/4 my-1 text-center flex flex-col gap-2 p-2 mx-auto">
        <MenuRadio selected={size} options={sizeOptions} onClick={setSize} />
        <MenuRadio
          selected={titleColor}
          options={titleOptions}
          onClick={setTitleColor}
        />

      </div>

      <div className="my-4 flex justify-center">
        <Button primary medium onClick={downloadImage}>
          Download Imagem
        </Button>
      </div>
    </main>
  );
}

export default PostsGea
