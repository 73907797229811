import { Link } from "react-router-dom";

import styles from "./MenuItem.module.css";

const MenuItem = ({ icon, label, route, external }) => {
  if (external) {
    return (
      <a className={styles.Root} href={route}>
        <i className={`${styles.Icon} ${icon}`}></i>
        <span className={styles.Label}>{label}</span>
      </a>
    );
  }

  return (
    <Link className={styles.Root} to={route}>
      <i className={`${styles.Icon} ${icon}`}></i>
      <span className={styles.Label}>{label}</span>
    </Link>
  );
};

export default MenuItem;
