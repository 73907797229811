import { Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import Layout from "./features/layout/Layout";
import Post from "features/offersPost/Post";
import Offer from "features/offers/Offer";
import Offers from "features/offers/Offers";
import OffersPdf from "features/offersPdf/OffersPdf";
import OfferEmptyState from "features/offers/OfferEmptyState";
import OffersEmptyState from "features/offers/OffersEmptyState";
import Signature from "features/signature/Signature";
import Page404 from "features/layout/Page404";
import ToastProvider from "components/toast/ToastProvider";
import ProtectedRoute from "ProtectedRoute";

const queryClient = new QueryClient();

function App() {
  return (
    <div className="App">
      <ToastProvider>
        <QueryClientProvider client={queryClient}>
          <ProtectedRoute>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route path="offers" element={<Offers />}>
                  <Route index element={<OffersEmptyState />} />
                  <Route path=":offerId" element={<Offer />}>
                    <Route index element={<OfferEmptyState />} />
                    <Route path="post" element={<Post />} />
                    <Route path="pdf" element={<OffersPdf />} />
                  </Route>
                </Route>
                <Route path="signature" element={<Signature />} />
                <Route path="*" element={<Page404 />} />
              </Route>
            </Routes>
          </ProtectedRoute>
        </QueryClientProvider>
      </ToastProvider>
    </div>
  );
}

export default App;
