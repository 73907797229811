import Toast from "./Toast";

const ToastContainer = ({ toasts, deleteToast }) => {
  return (
    <div className="fixed bottom-10 w-full z-50">
      <div className="max-w-xl mx-auto">
        {toasts &&
          toasts.map((toast) => (
            <Toast
              id={toast.id}
              key={toast.id}
              type={toast.type}
              message={toast.message}
              deleteToast={deleteToast}
            />
          ))}
      </div>
    </div>
  );
};

export default ToastContainer;
