import React from "react";
import ToastContext from "components/toast/ToastContext";

const useToast = () => {
  const context = React.useContext(ToastContext);

  if (context === undefined) {
    throw new Error("useToast hook must be used within a ToastProvider");
  }

  return context;
};

export default useToast;
