import styles from "./Content.module.css";

const Content = ({ title, children }) => {
  return (
    <div className={styles.Root}>
      <div className={styles.Title}>{title}</div>
      {children}
    </div>
  );
};

export default Content;
