import useOffers from "./useOffers";

const useOffer = (offerId) => {
  const { isLoading, data } = useOffers();

  return {
    isLoading,
    offer:
      data?.offers?.find(
        (o) => parseInt(o.CustomOfferID) === parseInt(offerId)
      ) || null,
  };
};

export default useOffer;
