import { useNavigate } from "react-router-dom";

const Offer = ({ offer }) => {
  const navigate = useNavigate();
  const {
    AccommodationName,
    OfferTitle,
    Nights,
    LeadInPrice,
    ImageURL,
    CustomOfferID,
  } = offer;

  return (
    <li
      className="flex px-3 py-3 cursor-pointer hover:bg-gray-100"
      onClick={() => navigate(`/offers/${CustomOfferID}`)}
    >
      <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
        <img
          src={ImageURL}
          alt="Salmon orange fabric pouch with match zipper, gray zipper pull, and adjustable hip belt."
          className="h-full w-full object-cover object-center"
        />
      </div>

      <div className="ml-2 flex flex-1 flex-col">
        <div>
          <div className="flex justify-between text-base font-medium text-gray-900">
            <h3>{OfferTitle}</h3>
            <p className="ml-2">{LeadInPrice} €</p>
          </div>
          <p className="mt-1 text-xs text-gray-500">{AccommodationName}</p>
        </div>
        <div className="flex flex-1 items-end justify-between text-sm">
          {Nights && <p className="text-gray-500">{Nights} Noites</p>}

          <div className="flex"></div>
        </div>
      </div>
    </li>
  );
};

export default Offer;
