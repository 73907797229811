import React from "react";

const initialContextState = {
  toast: () => {
    throw new Error(
      `You do not need to use this context by ref, please use useDialog hook`
    );
  },
};

const ToastContext = React.createContext(initialContextState);

export default ToastContext;
