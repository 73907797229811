import { useState } from "react";
import useEnv from "./useEnv";
import useToast from "./useToast";

const useFetch = (url) => {
  const { apiUrl } = useEnv();
  const { toast } = useToast();

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchAPI = async () => {
    try {
      setLoading(true);

      const res = await fetch(`${apiUrl}${url}`, {
        credentials: "include",
        // mode: 'no-cors'
      });

      const jsonRes = await res.json();
      setData(jsonRes);

      toast("success", jsonRes.msg);
    } catch (err) {
      console.log(err);
      setError(err);
      toast("error", err.msg);
    } finally {
      setLoading(false);
    }
  };

  return { data, error, loading, fetch: fetchAPI };
};

export default useFetch;
