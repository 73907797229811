import React, { forwardRef } from "react";
import Footer from "./Footer";
import { isFirefox } from "react-device-detect";

import styles from "./ComponentToPrint.module.css";

const ComponentToPrint = forwardRef(({ offer, agency }, ref) => {
  const {
    AccommodationName,
    OfferTitle,
    Description,
    LeadInPrice,
    PriceType,
    ImageURL,
    WhatsIncluded,
    Nights,
  } = offer;

  return (
    <div
      ref={ref}
      className="p-5 bg-white"
      style={{ height: isFirefox ? "1050px" : "1120px" }}
    >
      <div
        className="bg-white h-full"
        style={{ position: "relative", fontFamily: "noir-regular" }}
      >
        <div
          className={styles.Capa}
          style={{
            backgroundSize: "cover",
            height: "400px",
            backgroundImage: `url(${ImageURL})`,
          }}
        ></div>
        <div className={styles.Noites}>{Nights && `${Nights} noites`}</div>
        <div className={styles.Title}>{OfferTitle}</div>
        <div className={styles.Hotel}>{AccommodationName}</div>

        <div className="text-center">
          <div className={styles.Price}>
            <div className={styles.Desde}>{PriceType}</div>
            <div className={styles.Preco}>{LeadInPrice} €</div>
          </div>
        </div>

        <div className={styles.Descricao}>{Description}</div>

        <div id={styles.Footer}>
          <div className={styles.Incluido}>{WhatsIncluded}</div>
          <hr style={{ margin: "15px 0px" }} />
          <Footer agency={agency} />
        </div>
        <div className="clearfix"></div>
      </div>
    </div>
  );
});

export default ComponentToPrint;
